var stickyHeader = true;
// var flyoutNav = false;
// var fullscreenNav = false;
// var inlineNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

var countUpStats = true;
var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

// var sidebarCards = false; // Make sure to set a card layout prefab for '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'

// var visualAppealsLoadMore = false;

$(document).ready(function () {
    // sticky header search append to menu admin
    const menuAdmin = $("#menuAdmin");
    const hdrSearch = $("#pageHeader .headerContent .header-search");
    hdrSearch.prependTo(menuAdmin);

    // footer: address + enquiries + social medias + awards
    const ftrAddress = $("#pageFooterWrapper .FooterAddress");
    const ftrGeneralEnquiries = $("#pageFooterWrapper .FooterGeneralEnquiries");
    const ftrMediaEnquiries = $("#pageFooterWrapper .FooterMediaEnquiries");
    const ftrServices = $("#pageFooterWrapper .FooterServices");
    ftrGeneralEnquiries.appendTo(ftrAddress);
    ftrMediaEnquiries.appendTo(ftrAddress);
    ftrServices.appendTo(ftrAddress);
    
    // footer: links + copyright
    const ftrQuick = $("#pageFooterWrapper .FooterQuick");
    const ftrSocial = $("#pageFooterWrapper .FooterSocial");
    const ftrCredits = $("#pageFooterWrapper .FooterCredits");
    const ftrCopyright = $("#pageFooterWrapper .FooterCopyright");
    ftrSocial.appendTo(ftrQuick);
    ftrCredits.appendTo(ftrQuick);
    ftrCopyright.appendTo(ftrQuick);

    // move menu to between logo and main CTA
    function handleMenu() {
        if ($(window).width() > 576) {
            // Check if the menu's parent is the header content or not
            if (!$("#menuMain").parent().is($("#pageHeader .headerContent"))) {
                // Else append the menu to the header content
                $("#menuMain").appendTo("#pageHeader .headerContent");
            }
            if (!$("#menuAdmin").parent().is($("#pageHeader .menuAdminContainer"))) {
                // Else append the menu to the header content
                $("#menuAdmin").appendTo("#pageHeader .menuAdminContainer");
            }
        } else {
            // Check if the menu is inserted after or not
            if (!$("#pageHeader .headerContent").next().is($("#menuMain"))) {
                // Else insert the new element after the target element
                $("#menuMain").insertAfter("#pageHeader .headerContent");
            }
            if (!$("#menuMain .header-search").next().is($("#menuAdmin"))) {
                // Else insert the new element after the target element
                $("#menuAdmin").prependTo("#menuMain");
            }
        }
    }
    
    // Initial call to handleScreenSize
    handleMenu();
    
    // Attach the handleScreenSize function to the window resize event
    $(window).on('resize', handleMenu);

    // Move sub banner descriptions out to below with the content
    if ($(".listingIntro").length) {
        $(".listingIntro").prependTo(".pageWrapper > section > .contentBlockWrapper > .contentBlock");
        $(".listingIntro").prependTo(".appealListingPage .donorsListWrapper");
        $(".listingIntro").prependTo(".postCreate > .contentBlockWrapper > .contentBlock");
    }

    // Check if an <img> tag was found
    if (!($('body:not(.homepage) .carouselImageSingle .carouselSlide').find('img').length > 0)) {
        $('body:not(.homepage) .carouselImageSingle').remove();
    }

    // Check if any page title is missing
    const pageBanner = $('body:not(.homepage) .pageWrapper .headerWrapper .headerText');
    if (!(pageBanner.find('.title').length > 0)) {
        let pageTitle = pageBanner.find(".breadcrumbWrapper .breadcrumbLink a").text().trim();
        $('<h1 class="title">' + pageTitle + '</h1>').appendTo(pageBanner);
    }

    // Converts unclickable posts as actual unclickables
    $('.listedPost.PostCategory_unclickable').each(function () {
        $(this).find('a').removeAttr("href");
        $(this).find('a').prop("onclick", null).css("cursor", "auto");
        $(this).prop("onclick", null).css("cursor", "auto");
    });

    // On the homepage:
    if ($('body').hasClass('homepage')) {
        // Feed: Stories
        const storiesFeed = $(".StoriesFeed");
        const storiesBlurb = $(".homefeaturecategory-storiesblurb");
        const storiesButton = $(".homefeaturecategory-storiesbutton");
        storiesBlurb.wrapInner('<article></article>');
        storiesFeed.appendTo(storiesBlurb);
        storiesButton.appendTo(storiesBlurb);
        storiesBlurb.wrapInner('<div class="wrapper"></div>');

        // CTA: banner type
        const ctaBannerImage = $(".homefeaturecategory-ctabannerimage");
        const ctaBannerContent = $(".homefeaturecategory-ctabannercontent");
        ctaBannerImage.wrapInner('<figure></figure>');
        ctaBannerContent.wrapInner('<article></article>');
        ctaBannerContent.appendTo(ctaBannerImage);

        // CTA: box type
        const ctaBoxImage = $(".homefeaturecategory-ctaboximage");
        const ctaBoxContent = $(".homefeaturecategory-ctaboxcontent");
        ctaBoxImage.wrapInner('<figure></figure>');
        ctaBoxContent.wrapInner('<article></article>');
        ctaBoxContent.prependTo(ctaBoxImage);

        // Donation: image + content + form
        const donationImage = $(".homefeaturecategory-donationimage");
        const donationContent = $(".homefeaturecategory-donationcontent");
        const donationForm = $(".homefeaturecategory-donationform");
        donationImage.wrapInner('<figure></figure>');
        donationContent.wrapInner('<article></article>');
        donationForm.appendTo(donationContent);
        donationContent.appendTo(donationImage);
        donationImage.wrapInner('<div class="wrapper"></div>');
    }

    // on the interactive map page
    if ($('body').hasClass('PostCategory_interactive-map')) {
        initInteractiveMap();
        $(window).resize(function() {
            initInteractiveMap();
        });
    };
});

function initInteractiveMap() {
    if ($(window).width() > 991) {
        pinpointMapButtons();
        $(window).resize(function() {
            pinpointMapButtons()
        });
        $(".map-pin").on("mouseenter", function() {
            var countryId = "#" + $(this).data("id");
            $("#world_map .map.active").removeClass("active");
            $(`#world_map ${countryId}`).addClass("active");
        });
    }
}

// function to pinpoint buttons to the map
function pinpointMapButtons() {
    $("#map-buttons .map-pin").each(function() {
        var $this = $(this);
        var countryId = $this.data("id");
        var countryEl = document.querySelector(`#world_map #${countryId}`);
        var positions = absolutePosition(countryEl);
        switch(countryId) {
            case "North-East":
                var offsetY = positions.top + (positions.height / 2) - 30;
                var offsetX = positions.left + (positions.width / 2) - 30;
                $(`.map-pin[data-id="${countryId}"]`).css({ top: offsetY + "px", left: offsetX + "px", bottom: "unset" });
                break;
            case "North-West":
                var offsetY = positions.top + 50;
                var offsetX = positions.left - 75;
                $(`.map-pin[data-id="${countryId}"]`).css({ top: offsetY + "px", left: offsetX + "px", bottom: "unset" });
                break;
            case "Yorkshire":
                var offsetY = positions.top + (positions.height / 2) - 60;
                var offsetX = positions.left + (positions.height / 2) - 25;
                $(`.map-pin[data-id="${countryId}"]`).css({ top: offsetY + "px", left: offsetX + "px", bottom: "unset" });
                break;
            case "East-Midlands":
                var offsetY = positions.top + 40;
                var offsetX = positions.left + 50;
                $(`.map-pin[data-id="${countryId}"]`).css({ top: offsetY + "px", left: offsetX + "px", bottom: "unset" });
                break;
            case "East-of-England":
                var offsetY = positions.top + (positions.height / 2) - 40;
                var offsetX = positions.left + (positions.width / 2) - 40;
                $(`.map-pin[data-id="${countryId}"]`).css({ top: offsetY + "px", left: offsetX + "px", bottom: "unset" });
                break;
            case "South-East":
                var offsetY = positions.top + (positions.height / 2) + 25;
                var offsetX = positions.left + 75;
                $(`.map-pin[data-id="${countryId}"]`).css({ top: offsetY + "px", left: offsetX + "px", bottom: "unset" });
                break;
            case "South-West":
                var offsetY = positions.top + (positions.height / 2) - 10;
                var offsetX = positions.left + 50;
                $(`.map-pin[data-id="${countryId}"]`).css({ top: offsetY + "px", left: offsetX + "px", bottom: "unset" });
                break;
            case "Greater-London":
                var offsetY = positions.top - 15;
                var offsetX = positions.left - (positions.width / 2) - 15;
                $(`.map-pin[data-id="${countryId}"]`).css({ top: offsetY + "px", left: offsetX + "px", bottom: "unset" });
                break;  
            case "West-Midlands":
                var offsetY = positions.top + (positions.height / 2) - 40;
                var offsetX = positions.left - 50;
                $(`.map-pin[data-id="${countryId}"]`).css({ top: offsetY + "px", left: offsetX + "px", bottom: "unset" });
                break;
            default:
                var offsetY = positions.top;
                var offsetX = positions.left;
                $(`.map-pin[data-id="${countryId}"]`).css({ top: offsetY + "px", left: offsetX + "px", bottom: "unset" });
            }
        });
}

// function to get absolute positioning on the screen
function absolutePosition(el) {
    var
        found,
        left = 0,
        top = 0,
        width = 0,
        height = 0,
        offsetBase = absolutePosition.offsetBase;
    if (!offsetBase && document.body) {
        offsetBase = absolutePosition.offsetBase = document.createElement('div');
        offsetBase.style.cssText = 'position:absolute;left:0;top:0';
        document.body.appendChild(offsetBase);
    }
    if (el && el.ownerDocument === document && 'getBoundingClientRect' in el && offsetBase) {
        var boundingRect = el.getBoundingClientRect();
        var baseRect = offsetBase.getBoundingClientRect();
        found = true;
        left = boundingRect.left - baseRect.left;
        top = boundingRect.top - baseRect.top;
        width = boundingRect.right - boundingRect.left;
        height = boundingRect.bottom - boundingRect.top;
    }
    return {
        found: found,
        left: left,
        top: top,
        width: width,
        height: height,
        right: left + width,
        bottom: top + height
    };
}
